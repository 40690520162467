import React, { useState, useContext } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { GlobalContext } from "../context/Global";
import { useNavigate } from "react-router-dom";
import HeaderBack from "../utils/HeaderBack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputField from "../utils/InputField";
import Box from "@mui/material/Box";
import Heading from "../utils/typography/Heading";
import CircularProgress from "@mui/material/CircularProgress";
import PrimaryButton from "../utils/Button";
import Alert from "@mui/material/Alert";
import base_url, { base_slack_channel } from "../backendUrl";
import sendSlackNotification from "../utils/sendSlackNotification";

function CreateGBP() {
  const [globals] = useContext(GlobalContext);

  const [contact, setContact] = useState("");
  const [regionCode, setRegionCode] = useState("US");

  const [bizName, setbizName] = useState("");
  const [suiteNumber, setsuiteNumber] = useState("");
  const [country, setCountry] = useState("+1_US");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [streetAddress, setStreetAddress] = useState("");

  const [location, setLocation] = useState("");
  const [locationPredictions, setLocationPredictions] = useState([]);
  const [loader, setLoader] = useState(false);

  const [place_id, setPlace_id] = useState("");
  const [optsent, setOtpsent] = useState(false);
  const [wotp, setWotp] = useState(false);

  const navigate = useNavigate();
  const contactCountry = [
    { label: "UK (+44)", value: "+44_UK" },
    { label: "CA (+1)", value: "+1_CA" },
    { label: "US (+1)", value: "+1_US" },
    { label: "IN (+91)", value: "+91_IN" },
  ];

  const handleContact = (e) => {
    setContact(e.target.value);
  };

  const handleLocationChange = (event, value) => {
    console.log("location change");
    let place_id = value.place_id;

    if (place_id) {
      console.log(place_id);
      fetch(`${base_url}/chrone_webapp/place-id-to-info?place_id=${place_id}`) // Replace with your API endpoint
        .then((response) => {
          console.log(response);
          if (!response.ok) {
            throw new Error("HTTP status " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          localStorage.setItem("ref_place_id", place_id);
          localStorage.setItem(
            "administrative_area",
            data.res.administrative_area
          );
          localStorage.setItem("locality", data.res.locality);
          localStorage.setItem("latitude", data.res.latitude);
          localStorage.setItem("longitude", data.res.longitude);
          localStorage.setItem("postal_code", data.res.postal_code);
          localStorage.setItem("address_lines", data.res.address_lines);
          if (data.res.postal_code) {
            setPostalCode(data.res.postal_code);
          }
          if (data.res.address_lines) {
            setStreetAddress(data.res.address_lines.join(", "));
          }
          if (data.res.locality) {
            setCity(data.res.locality);
          }
          if (data.res.administrative_area) {
            setState(data.res.administrative_area);
          }
          if (value.description) {
            setLocation(value.description);
          }
          if (value.place_id) {
            setPlace_id(value.place_id);
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  const handleLocationInputChange = async (event, value) => {
    console.log("handleLocationInputChange");
    const response = await fetch(
      `${base_url}/lead-magnet/places-autocomplete?place=${value}`
    );
    const data = await response.json();
    setLocationPredictions(data.predictions);
  };

  const proceed2verify = () => {
    setLoader(true);
    const addressLines = streetAddress.split(", ");
    const data = {
      business_location: streetAddress,
      country_code_num: country.split("_")[0],
      business_email: localStorage.getItem("email"),
      business_phone: contact,
      country_code: country.split("_")[0],
      region_code: regionCode,
      postal_code: postalCode,
      business_name: bizName,
      door_num: suiteNumber,
      address_lines: addressLines.slice(0, 4),
      administrative_area: state,
      locality: city,
      latitude: parseFloat(localStorage.getItem("latitude")),
      longitude: parseFloat(localStorage.getItem("longitude")),
      ref_place_id: localStorage.getItem("ref_place_id"),
      is_test: false,
      is_retry: false,
    };
    console.log(data);
    sendSlackNotification(base_slack_channel, `Data: ${JSON.stringify(data)}`);

    const response = fetch(`${base_url}/chrone_webapp/create-gbp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data) => {
        console.log(data);
        try {
          sendSlackNotification(
            base_slack_channel,
            `Log for Devlopers:\n\nResponse from chrone_webapp/create-gbp API - ${JSON.stringify(
              data
            )}`
          );
        } catch (error) {}
        localStorage["location"] = data.location_name;
        localStorage["business_location"] = data.location_name;
        localStorage["verification_token"] = data.verification_token;
        localStorage["place_id"] = "";
        if (data.verification_token == "AUTO") {
          const location = localStorage["location"];
          const item = localStorage["srcTag"];

          let payload = {
            email: localStorage["email"],
            place_id: localStorage["place_id"],
            location_name: localStorage["location"],
            pd_id: localStorage["pd_id"] ?? "",
            gbp_created: true,
          };

          sendSlackNotification(
            base_slack_channel,
            `Log for Devlopers: item - ${item} | location - ${location}`
          );

          if (
            window.location.href.includes("web.chrone") ||
            localStorage["test_source"] === "web.chrone.io"
          ) {
            payload["src"] = "Web";
          } else {
            payload["src"] = "App";
          }
          if (location != undefined) {
            const url = `${base_url}/gmb_save_biz_data_react`;
            const response1 = await fetch(url, {
              method: "post",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(payload),
            });
            sendSlackNotification(
              base_slack_channel,
              `Log for Devlopers: /gmb_save_biz_data_react called`
            );
            const resp = await response1.json();
            try {
              const text_resp = await response1.text();
              sendSlackNotification(
                base_slack_channel,
                `Log for Devlopers: /gmb_save_biz_data_react response - ${text_resp}`
              );
            } catch (error) {}
            localStorage.setItem("uid", resp["res"]["uid"]);
          }

          window.location.href = localStorage["uid"]
            ? `${window.location.origin}/onboard/priming?uid=${localStorage["uid"]}`
            : `${window.location.origin}/onboard/priming`;

          setLoader(false);
        } else if (
          data.verification_token == null ||
          data.verification_token == undefined ||
          data.verification_token == ""
        ) {
          setWotp(true);
          setLoader(false);
          setTimeout(() => {
            setWotp(false);
            // window.location.reload(true);
          }, 3000);
        } else {
          setTimeout(() => {
            setOtpsent(true);
            window.location.href = `${
              window.location.origin
            }/onboard/verify-otp?countrycode=${
              country.split("_")[0]
            }&contact=${contact}`;
            setLoader(false);
          }, 3000);
        }
      })
      .catch((error) => {
        setWotp(true);
        setLoader(false);
        // setTimeout(() => {
        //     window.location.reload(true);
        // }, 3000);
        sendSlackNotification(
          base_slack_channel,
          `Log for Devlopers: ${error.text()} ${error.toString()}`
        );
        console.error("Error:", error);
      });
  };

  console.log(location, location.length || location != "");

  return (
    <div
      style={{
        height: globals.height,
        width: globals.width,
        backgroundSize: "contain",
        margin: "auto",
        padding: "0 20px",
      }}>
      <HeaderBack />
      <Heading>Create Google Business Profile</Heading>
      <Box
        sx={{
          border: "1px solid #B0B0B0",
          borderRadius: "13px",
          display: "flex", // Here we are using Flexbox
          alignItems: "center", // Align items in the center
          p: 1, // Padding
          height: "43px",
          margin: "2rem 0 0 0",
        }}>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
            setRegionCode(e.target.value.split("_")[1]);
          }}
          autoWidth
          label={null}
          sx={{
            "& .MuiOutlinedInput-root": {
              border: "none",
              "&:hover": {
                border: "none",
              },
              "&.Mui-focused": {
                border: "none",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            flex: 0.25, // Adjusted for 25% of the width
          }}>
          {contactCountry.map((item, index) => (
            <MenuItem key={index} value={item.value} name={item.label}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <TextField
          onBlur={(e) => {
            sendSlackNotification(
              base_slack_channel,
              `Contact no: ${e.target.value}`
            );
          }}
          onChange={handleContact}
          sx={{
            flex: "0.80", // Adjusted for 75% of the width
            alignContent: "center",
            paddingBottom: "10px",
            width: "100%", // Make sure it takes up full width
          }}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 10);
          }}
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            sx: { color: "grey" }, // This changes the color of the label
          }}
          id="standard-basic"
          label="Contact Number *"
          variant="standard"
          type="number"
          value={contact}
          maxLength={10}
        />
      </Box>

      <InputField
        label="Business Name *"
        defaultValue=""
        onChange={(e) => {
          setbizName(e.target.value);
        }}
        onBlur={(e) => {
          sendSlackNotification(
            base_slack_channel,
            `Business Name: ${e.target.value}`
          );
        }}
      />
      <Autocomplete
        value={location}
        onChange={handleLocationChange}
        onInputChange={handleLocationInputChange}
        options={locationPredictions}
        getOptionLabel={(option) => {
          return option.description ?? location;
        }}
        renderOption={(props, option) => (
          <li {...props}>{option.description}</li>
        )}
        renderInput={(params) => (
          <TextField
            onChange={(e) => {
              console.log(e.target.value);
            }}
            value={location}
            sx={{ "& .MuiOutlinedInput-root": { borderRadius: 3 } }}
            {...params}
            label="Business Location *"
            fullWidth
            margin="normal"
          />
        )}
      />
      {(location.length || location != "") && (
        <>
          <InputField
            label="Suite/Door No."
            defaultValue=""
            onChange={(e) => {
              setsuiteNumber(e.target.value);
            }}
            onBlur={(e) => {
              sendSlackNotification(
                base_slack_channel,
                `Suite/Door no: ${e.target.value}`
              );
            }}
            width="43%"
            marginRight="4%"
          />
          <InputField
            label="Postal Code *"
            defaultValue=""
            onChange={(e) => {
              setPostalCode(e.target.value);
            }}
            onBlur={(e) => {
              sendSlackNotification(
                base_slack_channel,
                `Postal code: ${e.target.value}`
              );
            }}
            width="53%"
            type="text"
            value={postalCode}
            maxLength={8}
            InputLabelProps={postalCode ? { shrink: true } : {}}
          />
          <InputField
            label="Street Address *"
            defaultValue=""
            onChange={(e) => {
              setStreetAddress(e.target.value);
            }}
            onBlur={(e) => {
              sendSlackNotification(
                base_slack_channel,
                `Street Address: ${e.target.value}`
              );
            }}
            value={streetAddress}
            InputLabelProps={streetAddress ? { shrink: true } : {}}
          />
          <InputField
            label="City *"
            defaultValue=""
            onChange={(e) => {
              setCity(e.target.value);
            }}
            onBlur={(e) => {
              sendSlackNotification(
                base_slack_channel,
                `City: ${e.target.value}`
              );
            }}
            value={city}
            InputLabelProps={city ? { shrink: true } : {}}
          />
          <InputField
            label="State *"
            defaultValue=""
            onChange={(e) => {
              setState(e.target.value);
            }}
            onBlur={(e) => {
              sendSlackNotification(
                base_slack_channel,
                `State: ${e.target.value}`
              );
            }}
            value={state}
            InputLabelProps={state ? { shrink: true } : {}}
          />
        </>
      )}

      {optsent && (
        <div style={{ margin: "2rem 0" }}>
          <Alert severity="info">OTP has been sent successfully</Alert>
        </div>
      )}
      {wotp && (
        <div style={{ margin: "2rem 0" }}>
          <Alert severity="error">An Error Occured Please retry</Alert>
        </div>
      )}
      <Box
        sx={{
          width: globals.width * 0.9,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
        }}>
        <PrimaryButton
          style={{ cursor: "pointer" }}
          onClick={() => {
            console.log(`Contact ${contact?.length}`);
            console.log(`bizName ${bizName?.length}`);
            console.log(`suiteNumber ${suiteNumber?.length}`);
            console.log(`streetAddress ${streetAddress?.length}`);
            console.log(`postalCode ${postalCode?.length}`);
            console.log(`city ${city?.length}`);
            console.log(`state ${state?.length == 0}`);

            if (!contact && contact?.length == 0) {
              setLoader(false);
              sendSlackNotification(
                base_slack_channel,
                `User alerted with - Enter a valid Contact Number`
              );
              alert("Enter a valid Contact Number");
            } else if (!bizName && bizName?.length == 0) {
              setLoader(false);
              sendSlackNotification(
                base_slack_channel,
                `User alerted with - Enter a valid Business Name`
              );
              alert("Enter a valid Business Name");
            } else if (!streetAddress && streetAddress?.length == 0) {
              setLoader(false);
              sendSlackNotification(
                base_slack_channel,
                `User alerted with - Enter a valid Street Address`
              );
              alert("Enter a valid Street Address");
            } else if (!postalCode && postalCode?.length == 0) {
              setLoader(false);
              sendSlackNotification(
                base_slack_channel,
                `User alerted with - Enter a valid Postal code`
              );
              alert("Enter a valid Postal code");
            } else if (!city && city?.length == 0) {
              setLoader(false);
              sendSlackNotification(
                base_slack_channel,
                `User alerted with - Enter a valid City`
              );
              alert("Enter a valid City");
            } else if (!state && state?.length == 0) {
              setLoader(false);
              sendSlackNotification(
                base_slack_channel,
                `User alerted with - Enter a valid State`
              );
              alert("Enter a valid State");
            } else {
              proceed2verify();
            }
            // if(
            //     contact?.length > 7 &&
            //     bizName?.length>0 &&
            //     suiteNumber?.length>0 &&
            //     streetAddress?.length>0 &&
            //     postalCode?.length>4 &&
            //     city?.length>0 &&
            //     state?.length>0
            // ) {

            // }
          }}
          forceEnabled={(location.length || location != "") ?? false}>
          <>
            VERIFY
            {loader ? (
              <CircularProgress
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "10px",
                  color: "#000000",
                }}
              />
            ) : null}
          </>
        </PrimaryButton>
      </Box>
    </div>
  );
}

export default CreateGBP;
