import React from "react";
import { GlobalContext } from "../context/Global";
import { useContext, useState, useEffect } from "react";
import Heading from "../utils/typography/Heading";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SubHeading from "../utils/typography/SubHeading";
import Checkbox from "@mui/material/Checkbox";
import FixedBottom from "../utils/FixedBottom";
import { TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import base_url, { base_slack_channel } from "../backendUrl";
import HeaderBack from "../utils/HeaderBack";
import Box from "@mui/material/Box";
import sendSlackNotification from "../utils/sendSlackNotification";
import postOnboardingStage from "../apis/postOnboardingStage";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Bounce } from "react-toastify";

const sendToken = async (token) => {
  const server_name = "https://timely.work";
  const api = `${server_name}/instagram/v2/insert-long-token`;
  const payload = {
    uid: localStorage.getItem("uid"),
    long_token: token,
  };

  try {
    // debugger;
    const response = await fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    console.log("Success:", responseData);
  } catch (error) {
    console.error("Error:", error);
  }
};

const SetPhoneNumber = () => {
  const [globals] = useContext(GlobalContext);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [phoneNumber, setPhoneNum] = useState("");
  const [clientContact, setClientContact] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [check, setCheck] = useState(true);
  const [country, setCountry] = useState("+1");
  const [countryclient, setCountryClient] = useState("+1");
  const navigate = useNavigate();

  function isMobileDevice() {
    return true;
  }

  const handleChange = (event) => {
    const value = event.target.value.replace(/\D/g, "").slice(0, 10); // Allow only digits and limit to 10
    setPhoneNum(value);
  };

  const handleClientContactChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 10); // Allow only digits and limit to 10
    setClientContact(value);
  };
  const contactCountry = [
    { label: "UK (+44)", value: "+44" },
    { label: "CA (+1)", value: "+ 1" },
    { label: "US (+1)", value: "+1" },
    { label: "IN (+91)", value: "+91" },
  ];

  const handleDone = () => {
    const place_id = localStorage.getItem("place_id");
    const uid = localStorage.getItem("uid");
    var phones = [];
    var phone_numbers_message = `First Name: ${firstName}\nLast Name: ${lastName}\nPhone Numbers:\n`;
    if (check) {
      phones = [
        {
          type: "general_comm",
          phone: clientContact,
          country_code: countryclient,
        },
      ];
      phone_numbers_message =
        phone_numbers_message +
        `  • ${countryclient} ${clientContact} (For Client and Chrone)`;
    } else {
      phones = [
        {
          type: "lead_comm",
          phone: clientContact,
          country_code: countryclient,
        },
        { type: "general_comm", phone: phoneNumber, country_code: country },
      ];
      phone_numbers_message =
        phone_numbers_message +
        `  • ${countryclient} ${clientContact} (For Client)\n  • ${country} ${phoneNumber} (For Chrone)`;
    }

    sendSlackNotification(base_slack_channel, phone_numbers_message);

    const data = {
      uid: uid,
      place_id,
      phones,
      first_name: firstName,
      last_name: lastName,
    };
    const url = `${base_url}/name-contacts-sync`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data) => {
        console.log(data);
        const item = localStorage["srcTag"];

        await sendSlackNotification(
          "temp-channel",
          `SetPhoneNumber.js localStorage["srcTag"] - ${localStorage["srcTag"]}, localStorage["uid"] - ${localStorage["uid"]}`
        );
        await postOnboardingStage(
          "completed",
          localStorage["uid"],
          localStorage["email"]
        );

        if (
          window.location.href.includes("web.chrone") ||
          localStorage["test_source"] === "web.chrone.io"
        )
          window.location.href = `${base_url}/old-dashboard-transit?place_id=${localStorage["place_id"]}&uid=${localStorage["uid"]}&location=${localStorage["location"]}`;
        else {
          window.location.href = `chrone://close-browser?uid=${localStorage["uid"]}`;
        }
      })
      .catch(async (error) => {
        console.error("Error:", error);
        await sendSlackNotification(
          "temp-channel",
          `SetPhoneNumber.js Some error has ocured : ${error}`
        );
        if (
          window.location.href.includes("web.chrone") ||
          localStorage["test_source"] === "web.chrone.io"
        )
          window.location.href = `${base_url}/old-dashboard-transit?place_id=${localStorage["place_id"]}&uid=${localStorage["uid"]}&location=${localStorage["location"]}`;
        else {
          window.location.href = `chrone://close-browser?uid=${localStorage["uid"]}`;
        }
      });
  };

  const location = useLocation();

  const viewportHeight = window.innerHeight; // Get the viewport height
  const tenVhInPx = 0.1 * viewportHeight; // Calculate 10vh in pixels
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const uid_value = params.get("uid"); // Get uid from URL params

    if (uid_value) {
      localStorage.setItem("uid", uid_value); // Store uid in local storage
    }
    const data_value = params.get("data"); // Replace 'data' with your actual query parameter name
    if (data_value) {
      sendSlackNotification(
        base_slack_channel,
        `Instagram connected successfully`
      );
      toast("Instagram connected", {
        position: "bottom-center",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          backgroundColor: "#5ABB6F",
          fontFamily: "Red Hat Display bold",
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: 800,
          color: "#fff",
        },
        transition: Bounce,
      });
      sendToken(data_value);
    } else {
      sendSlackNotification(base_slack_channel, `Instagram connection failed`);
      toast.error("Instagram connection failed", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          fontFamily: "Red Hat Display bold",
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: 800,
          color: "#fff",
        },
      });
    }
  }, [location]);

  return (
    <div
      style={{
        height: `${globals.height + tenVhInPx}px`,
        marginBottom: "110px",
        width: globals.width,
        backgroundSize: "contain",
        backgroundColor: "#ffffff",
        margin: "auto",
        padding: "0 20px",
      }}>
      <ToastContainer
        position="bottom-center"
        autoClose={true}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{
          margin: "20px", // External spacing to ensure border radius visibility
          borderRadius: "8px", // Border radius
          zIndex: 99999999999999, // Ensure it's on top
          overflow: "hidden",
        }}
      />
      <HeaderBack />
      <div style={{ padding: "0px 20px" }}>
        <Heading
          style={{
            textAlign: "left !important",
            fontSize: "18px",
          }}>
          First name
        </Heading>
        <Box
          sx={{
            border: "1px solid #B0B0B0",
            borderRadius: "13px",
            display: "flex", // Here we are using Flexbox
            alignItems: "flex-start", // Align items in the center
            p: 1, // Padding
            height: "43px", // Adjust as needed
            margin: "0.5rem 0",
          }}>
          <TextField
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            sx={{
              alignContent: "center",
              paddingBottom: "10px",
              width: "100%",
            }}
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              style: {
                color: "#000000", // Set label color to black
                top: "-8px",
              },
            }}
            id="standard-basic"
            label="Enter your first name"
            variant="standard"
            type="text"
            value={firstName}
          />
        </Box>
        <Heading
          style={{
            textAlign: "left !important",
            marginTop: "40px",
            fontSize: "18px",
          }}>
          Last name
        </Heading>
        <Box
          sx={{
            border: "1px solid #B0B0B0",
            borderRadius: "13px",
            display: "flex", // Here we are using Flexbox
            alignItems: "flex-start", // Align items in the center
            p: 1, // Padding
            height: "43px", // Adjust as needed
            margin: "0.5rem 0",
          }}>
          <TextField
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            sx={{
              alignContent: "center",
              paddingBottom: "0px",
              width: "100%",
            }}
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              style: {
                color: "#000000", // Set label color to black
                top: "-8px",
              },
            }}
            id="standard-basic"
            label="Enter your last name"
            variant="standard"
            type="text"
            value={lastName}
          />
        </Box>
        <Heading
          style={{
            textAlign: "left !important",
            marginTop: "40px",
            fontSize: "18px",
          }}>
          You can contact me on
        </Heading>
        <Box
          sx={{
            border: "1px solid #B0B0B0",
            borderRadius: "13px",
            display: "flex", // Here we are using Flexbox
            alignItems: "center", // Align items in the center
            p: 1, // Padding
            height: "43px", // Adjust as needed
            margin: "0.5rem 0",
          }}>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={country}
            onChange={(e) => {
              setCountry(e.target.value);
            }}
            autoWidth
            label={null}
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "none",
                "&:hover": {
                  border: "none",
                },
                "&.Mui-focused": {
                  border: "none",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}>
            {contactCountry.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <TextField
            onChange={handleClientContactChange}
            sx={{
              alignContent: "center",
              paddingBottom: "10px",
              width: "100%",
            }}
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              style: {
                color: "#000000", // Set label color to black
              },
            }}
            id="standard-basic"
            label="Enter phone number for updates"
            variant="standard"
            type="tel"
            value={clientContact}
            maxLength={10}
          />
        </Box>

        <div
          style={{
            display: "flex",
            justifyContent: "start",
            margin: "20px 0",
            alignItems: "center",
          }}>
          <Checkbox
            onClick={() => {
              setCheck(!check);
            }}
            style={{ color: "#000" }}
            {...label}
            defaultChecked
          />
          <SubHeading>Use the same number for clients</SubHeading>
        </div>

        {!check && (
          <Box
            sx={{
              border: "1px solid #B0B0B0",
              borderRadius: "13px",
              display: "flex", // Here we are using Flexbox
              alignItems: "center", // Align items in the center
              p: 1, // Padding
              height: "43px", // Adjust as needed
            }}>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={countryclient}
              onChange={(e) => {
                setCountryClient(e.target.value.replace(" ", ""));
              }}
              autoWidth
              label={null}
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: "none",
                  "&:hover": {
                    border: "none",
                  },
                  "&.Mui-focused": {
                    border: "none",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}>
              {contactCountry.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <TextField
              sx={{
                alignContent: "center",
                paddingBottom: "10px",
                width: "100%",
              }}
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                style: {
                  color: "#000000", // Set label color to black
                },
              }}
              onChange={handleChange}
              id="standard-basic"
              label="Enter phone number for clients"
              variant="standard"
              value={phoneNumber}
              type="tel"
            />
          </Box>
        )}
        <FixedBottom
          nextEnabled={
            (check && clientContact.length == 10) ||
            (!check && phoneNumber.length == 10 && clientContact.length == 10)
          }
          onClickNext={handleDone}
          buttonText="DONE"
          fillPercent={80}
        />
      </div>
    </div>
  );
};

export default SetPhoneNumber;
